import React from "react";
import {Button} from "antd";
import {useAuthContext} from "../contexts/AuthContext";
import styles from "../style/custom.module.css";
import {useTranslation} from "react-i18next";

const GoogleLogin = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const {t} = useTranslation();
  const {
    connectedEmails,
    startGmailAuth,
  } = useAuthContext();

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', padding: '16px 16px 32px', marginBottom: '16px', borderBottom: 'solid 1px lightgrey'}}>
        <Button
          className={[
            styles.buttonText,
            styles.signInButtonAlign,
            "btn btn-success btn-sm",
          ].join(" ")}
          onClick={startGmailAuth}
        >
          {connectedEmails && connectedEmails.length > 0 ? t('profile.connectedAccounts.reconnectButton') : t('profile.connectedAccounts.connectButton')}
        </Button>
      </div>
    </>
  );
};

export default GoogleLogin;
