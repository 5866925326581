import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APIGet } from '../../helpers/http.helper';
import styles from './../../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';

enum actionEnum {
  BLOCK = 'BLOCK',
  ACCEPT = 'ACCEPT',
  CREATECALENDAREVENT = 'CREATECALENDAREVENT',
  ACCEPTALL = 'ACCEPTALL',
  BLOCKALL = 'BLOCKALL',
}

const WebHook = () => {
  const [getPageHeader, setPageHeader] = useState('');
  const [getWaitingStatus, setWaitingStatus] = useState('');

  const location = useLocation();

  React.useEffect(() => {
    (async () => {
      const params = new URLSearchParams(location.search);

      const action = params.get('a');
      const taskID = params.get('tid');
      const userID = params.get('uid');
      const status = params.get('s');
      const eventUrl = params.get('eid');
      const queryString = params.get('qs');
      try {
        let response: any;

        if (action === actionEnum.CREATECALENDAREVENT) {
          setPageHeader('Creating Event');
          setWaitingStatus('Please wait...');
          response = await APIGet(`gmail/CreateCalendarEvents/${queryString}`);
        } else if (action === actionEnum.ACCEPTALL) {
          setPageHeader('Updating Preferences');
          setWaitingStatus('Please wait...');
          response = await APIGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
        }
        else if (action === actionEnum.ACCEPT) {
          setPageHeader('Updating Preferences');
          setWaitingStatus('Please wait...');
          response = await APIGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
        }
        else if (action === actionEnum.BLOCKALL) {
          setPageHeader('Updating Preferences');
          setWaitingStatus('Please wait...');
          response = await APIGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
        }
        else if (action === actionEnum.BLOCK) {
          setPageHeader('Updating Preferences');
          setWaitingStatus('Please wait...');
          response = await APIGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
        }
        else {
          setPageHeader('Updating Calendar');
          setWaitingStatus('Please wait...');
          response = await APIGet(`gmail/UpdateCalendarEvents/${taskID}/${userID}/${status}`);
        }

        if (response.ok) {
          if (action === actionEnum.CREATECALENDAREVENT) {
            let responseJson = await response.json();
            if (responseJson.status === 500) {
              setPageHeader('Something went wrong');
              setWaitingStatus('Please try again.');
              return;
            }
            else if (responseJson.status === 401) {
              setPageHeader('Gmail Disconnected');
              setWaitingStatus('Gmail token expired - please reconnect your Gmail account.');
              return;
            }
            if (responseJson.data) {
              setPageHeader('Created Event');
              setWaitingStatus('Redirecting to Event...');
              setTimeout(() => window.location.href = responseJson.data.htmlLink, 5000);
            }
          }
          else if (action === actionEnum.ACCEPTALL || action === actionEnum.ACCEPT || action === actionEnum.BLOCKALL || action === actionEnum.BLOCK) {
            let responseJson = await response.json();
            if (responseJson.status === 500) {
              setPageHeader('Something went wrong');
              setWaitingStatus('Please try again.');
              return;
            }
            else if (responseJson.status === 200) {
              setPageHeader('Updated Preferences');
              setWaitingStatus('Redirecting to Profile...');
              setTimeout(() => window.location.href = '/userProfile', 5000);
            }
          }
          else {
            if (eventUrl) {
              setPageHeader('Updated Event');
              setWaitingStatus('Redirecting to Event...');
              setTimeout(() => window.location.href = eventUrl, 5000);
            }
          }
        }
      } catch (error: any) {
        console.error('API request failed:', error.message);
      }
    })();
  }, [location.search]);

  return (
    <div className={styles.webhookContainer} style={{textAlign: 'center'}}>
      <div>
        <h4 style={{textAlign: 'center', marginBottom: '16px'}}>{getPageHeader}</h4>
        <p>{getWaitingStatus}</p>
      </div>
    </div>
  );
};

export default WebHook;