import Cookies from "js-cookie";

let token: null | string = Cookies.get('nm') || null;

// onForbidden should be assigned via setOnForbidden
let onForbidden: () => any = () => console.error('401 handler not bound in http helper');

export const setAuthToken = (newToken: string) => {
    token = newToken;
}
export const setOnForbidden = (newOnForbidden: typeof onForbidden) => {
    onForbidden = newOnForbidden;
}

export const clearAuthToken = () => {
    token = null;
}

const getPrivateHeaders = () => ({
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Frame-Options": "DENY",
    'Authorization': `Bearer ${token}`
});

export const APIPost = async (url: string, body: any) => {
    const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
        method: "POST",
        headers: getPrivateHeaders(),
        body: JSON.stringify(body),
    });
    if (post.status === 401) {
        onForbidden();
    }
    return post;
}

export const APIPut = async (url: string, body: any) => {
    const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
        method: "PUT",
        headers: getPrivateHeaders(),
        body: JSON.stringify(body),
    });
    if (post.status === 401) {
        onForbidden();
    }
    return post;
}

export const APIGet = async (url: string) => {
    const get = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
        method: "GET",
        headers: getPrivateHeaders(),
    });
    if (get.status === 401) {
        onForbidden();
    }
    return get;
}
