import React, {useCallback, useMemo} from 'react';
import type {MenuProps} from 'antd';
import {Dropdown} from 'antd';
import {Link} from "react-router-dom";
import styles from "../style/custom.module.css"
import {setLanguage} from "../translations";
import {useAuthContext} from "../contexts/AuthContext";
import i18next from "i18next";

const LanguageSelect: React.FC = () => {
  const { loggedIn } = useAuthContext();

  const handleLanguageSelect = useCallback((newLanguage: 'en' | 'fr') => {
    if (i18next.resolvedLanguage !== newLanguage) {
      setLanguage(newLanguage, Boolean(loggedIn));
    }
  }, [loggedIn]);

  const items: MenuProps['items'] = useMemo(() => [
    {
      label: <Link to="?lng=en" onClick={() => handleLanguageSelect('en')}>English</Link>,
      key: 'en',
    }, {
      label: <Link to="?lng=fr" onClick={() => handleLanguageSelect('fr')}>Français</Link>,
      key: 'fr',
    }
  ], [handleLanguageSelect]);

  return (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottom">
      <a className={styles.languageSelect} onClick={(e) => e.preventDefault()}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path
            d="m7.508,9.196c-.107-.464-.403-.886-.842-1.07-.206-.086-.413-.126-.613-.126-.689,0-1.287.475-1.443,1.147l-1.475,6.252c-.072.307.16.601.476.601.226,0,.422-.155.475-.375l.389-1.625h3.115l.374,1.621c.051.222.249.379.476.379h.012c.314,0,.546-.292.476-.598l-1.422-6.206Zm-2.793,3.804l.869-3.627c.051-.219.244-.373.469-.373.073,0,.149.016.226.049.111.046.213.197.253.371l.827,3.58h-2.645Zm14.805-9H4.52C2.038,4,.02,6.019.02,8.5v7c0,2.481,2.019,4.5,4.5,4.5h15c2.481,0,4.5-2.019,4.5-4.5v-7c0-2.481-2.019-4.5-4.5-4.5ZM1.02,15.5v-7c0-1.93,1.57-3.5,3.5-3.5h7v14h-7c-1.93,0-3.5-1.57-3.5-3.5Zm22,0c0,1.93-1.57,3.5-3.5,3.5h-7V5h7c1.93,0,3.5,1.57,3.5,3.5v7Zm-1-6.009v.018c0,.271-.22.491-.491.491h-.546c-.107.917-.517,2.904-2.085,4.341.701.354,1.574.595,2.658.648.26.013.464.229.464.49v.018c0,.281-.235.505-.516.491-1.469-.071-2.605-.448-3.484-.986-.879.538-2.015.915-3.484.986-.28.014-.516-.21-.516-.491v-.018c0-.26.204-.477.464-.49,1.085-.054,1.957-.295,2.659-.648-.54-.495-.943-1.055-1.243-1.614-.176-.329.059-.727.432-.727.181,0,.345.1.432.259.291.534.695,1.066,1.257,1.519,1.474-1.185,1.862-2.925,1.964-3.777h-5.473c-.271,0-.491-.22-.491-.491v-.018c0-.271.22-.491.491-.491h3.009v-.509c0-.271.22-.491.491-.491h.018c.271,0,.491.22.491.491v.509h3.009c.271,0,.491.22.491.491Z"/>
        </svg>
      </a>
    </Dropdown>
  )
};

export default LanguageSelect;