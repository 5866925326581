import React from 'react';
import styles from '../../style/custom.module.css'

import { useOnboardingContext, ONBOARD_STAGES } from "../../contexts/OnboardingContext";

import OnboardingStepper from "../onboardingStepper";
import Membership from '../membership';
import UserPreferences from '../userPreferences';

const UserProfile = () => {
  const {
    stage,
  } = useOnboardingContext();

  return (
    <div className={styles.userProfile} style={{ display: stage === ONBOARD_STAGES.LOADING ? 'none' : 'grid' }}>
      <OnboardingStepper />
      {(stage === ONBOARD_STAGES.SUBSCRIBE ||
        stage === ONBOARD_STAGES.COMPLETE)
        && <Membership />
      }
      {(stage !== ONBOARD_STAGES.GOOGLE_REAUTH && (
        <UserPreferences
          showTemplates={stage === ONBOARD_STAGES.CONFIG_PREFERENCES}
        />
      ))}
    </div>
  );
}

export default UserProfile;
