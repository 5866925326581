import React, {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Cookies, {CookieAttributes} from "js-cookie";
import {useTranslation} from "react-i18next";
import {jwtDecode} from "jwt-decode";
import {APIGet, APIPost, APIPut, setAuthToken, clearAuthToken} from "../helpers/http.helper";
import {setLanguage} from "../translations";
import i18next from "i18next";
import {setOnForbidden} from "../helpers/http.helper";
import {useGoogleLogin} from "@react-oauth/google";
import {notification} from "antd";

const cookieAttributes: CookieAttributes = process.env.NODE_ENV === 'development' ? {
    sameSite: 'Strict',
    secure: false,
} : {
    sameSite: 'Strict',
    secure: true,
}

type AuthContextType = {
    loggedIn: boolean | null,
    login: ({ username, password }: { username: string, password: string }) => Promise<void>,
    logout: () => void,
    connectedEmails: {
        email: string,
        status: 'connected' | 'disconnected',
        scopes: string[],
    }[] | undefined,
    startGmailAuth: () => void;
};

const AuthContext = createContext<AuthContextType>({
    loggedIn : null,
    login: ()=> Promise.reject(new Error('login function not implemented.')),
    logout: () => undefined,
    connectedEmails: undefined,
    startGmailAuth: () => Promise.reject(new Error('startGmailAuth function not implemented.')),
});

export const useAuthContext = (): AuthContextType => useContext(AuthContext);

export const AuthContextProvider = ({
    children
}: {
    children: ReactNode
}) => {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const { t } = useTranslation();
    const [loggedIn, setLoggedIn] = useState<AuthContextType['loggedIn']>(null)

    const logout = useCallback(() => {
        Cookies.remove('nm');
        clearAuthToken();
        localStorage.clear();
        setLoggedIn(false);
    }, []);

    // Our API helpers call onForbidden on 401, but we need to bind logout to it
    // for it to do anything. Do this.
    useEffect(() => {
        setOnForbidden(logout)
    }, [logout]);

    const login = useCallback(async ({ username, password }: {username: string, password: string}) => {
        const response = await APIPut('', {username: username.toLowerCase(), password: btoa(password)});

        if (response.status === 200) {
            const data = await response.json();
            Cookies.set('nm', data.token, cookieAttributes);

            setAuthToken(data.token);
            setLoggedIn(true);

            // TODO URGENT - need this from login response
            if (!data.language) {
                // No language is currently persisted
                //
                // @ts-ignore no typing available for resolvedLanguage
                setLanguage(i18next.resolvedLanguage, true);
            } else if (data.language !== i18next.resolvedLanguage) {
                // The user has manually changed the language from the default prior to
                // logging in AND the persisted language does not match the resolved
                // language
                //
                // @ts-ignore no typing available for resolvedLanguage
                setLanguage(i18next.resolvedLanguage, true);
            } else {
                // Set the language to the user's preferred language.
                // we do not want to persist - it's already persisted.
                setLanguage(data.language, false);
            }
        } else if (response.status === 401) {
            throw new Error(t('auth.errors.incorrectUserOrPassword'));
        } else {
            throw new Error(t('auth.errors.failedToLogin'));
        }
    }, [t]);

    useEffect(() => {
        (async () => {
            const jwt = Cookies.get('nm');
            if (jwt) {
                const {sub} = jwtDecode<{ sub: string }>(jwt);
                if (sub) {
                    let response = await APIGet(``);
                    if (response.status === 200) {
                        setLoggedIn(true);
                        return;
                    }
                }
            }
            logout();
        })();
    }, [logout]);

    const [connectedEmails, setConnectedEmails] = useState<AuthContextType['connectedEmails']>(undefined);

    const getConnectedEmails = useCallback(async () => {
        let response = await APIGet(
          `connected-accounts`
        );

        if (response.status === 200) {
            const data = await response.json();
            setRequiredScopes(data.requiredScopes);
            setConnectedEmails(data.accounts);
        } else {
            setConnectedEmails([]);
        }
    }, []);

    useEffect(() => {
        if (loggedIn && !connectedEmails) {
            getConnectedEmails();
        }
    }, [getConnectedEmails, connectedEmails, loggedIn]);

    const [hint, setHint] = useState<string | undefined>();
    const [requiredScopes, setRequiredScopes] = useState<string[]>([]);

    useEffect(() => {
        if (connectedEmails && connectedEmails.length > 0) {
            setHint(connectedEmails[0].email);
        }
    }, [connectedEmails, requiredScopes]);

    const startGmailAuth = useGoogleLogin({
        flow : "auth-code",
        redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
        // This is pretty unintuitive, but the only apparent way of updating the auth config is via a `state` prop
        // https://stackoverflow.com/a/77706002 - just mutating `hint` or `requiredScopes` alone does not work.
        state: hint,
        hint,
        scope: requiredScopes.join(' '),
        onSuccess: async (codeResponse: any) => {
            try {
                let response = await APIPost(
                  "connected-accounts",
                  {
                      code: codeResponse.code,
                  }
                );
                if (response.status === 412) {
                    notification.error({
                        message: t('onboarding.connect.scopeMissing'),
                        placement: "bottomRight"
                    });
                    return;
                } else {
                    const data = await response.json();
                    setConnectedEmails(data.accounts);
                }
            } catch (error) {
                console.error("OAuth2 Error: " + error);
            }
        },
        onError: (error) => console.log("Login Failed:", error),
    });

    const value = useMemo(() => ({
        loggedIn,
        login,
        logout,
        connectedEmails,
        startGmailAuth,
    }), [loggedIn, login, logout, connectedEmails, startGmailAuth]);

    if (loggedIn === null) {
        return <div>{t('common.loading')}</div>;
    }

    return (
        <AuthContext.Provider
            value={value}
        >
            {children}
        </AuthContext.Provider>
    );
};
