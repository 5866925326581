import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import {APIPost} from "../helpers/http.helper";

i18next.use(initReactI18next).use(LanguageDetector).init({
  detection: {
    lookupLocalStorage: 'nm_lng'
  },
  supportedLngs: ['en', 'fr'],
  fallbackLng: false,
  debug: process.env.NODE_ENV === 'development',
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  returnObjects: true,
});

export const setLanguage: (newLanguage: "en" | "fr", persistToBackend: boolean) => void = async (newLanguage, persistToBackend) => {
  await i18next.changeLanguage(newLanguage);
  if (persistToBackend) {
    const languageUpdateResponse = await APIPost('language', {
      language: i18next.resolvedLanguage
    });
    if (!languageUpdateResponse.ok) {
      const errorData = await languageUpdateResponse.json();
      // @ts-ignore TODO shouldn't have to do this, fix.
      throw new Error(errorData.message || i18next.t('auth.errors.failedToSetLanguage'));
    }
  }
}