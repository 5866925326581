import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Divider } from 'antd';
import {useTranslation} from "react-i18next";
import styles from '../../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';
import {useAuthContext} from "../../contexts/AuthContext";

const LoginPage = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const {t} = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<any>(null);

  const { login } = useAuthContext();

  const validateForm = () => {
    const newErrors: any = {};
    if (!username) newErrors.username = t('auth.errors.requiredEmail');
    if (!password) newErrors.password = t('auth.errors.requiredPassword');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      await login({ username, password });
    } catch (error: any) {
      console.error('Login error:', error.message);
      setErrors({ message: error.message });
    }
  };

  return (
    <div className={styles.login}>
      <Form onFinish={handleLogin} className={styles.authForm} colon={false}>
        <label>
          <span>{t('auth.emailLabel')}</span>
          <div>
            <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)}/>
            {errors?.username && <div className={styles.fieldError}>{errors?.username}</div>}
          </div>
        </label>
        <label>
          <span>{t('auth.passwordLabel')}</span>
          <div>
            <Input.Password type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            {errors?.password && <div className={styles.fieldError}>{errors?.password}</div>}
          </div>
        </label>
        <p className={styles.authFormAsideAction}>
          <Link to={`/forgotPassword?a=r&e=${encodeURIComponent(username)}`}>{t('auth.resetPassword')}</Link>
        </p>
        {errors?.message && <div className={styles.error}>{errors?.message}</div>}
        <div className={styles.authFormRow}>
          <Button className={[styles.buttonText, "btn btn-primary btn-sm"].join(' ')} htmlType="submit">
            {t('auth.login')}
          </Button>
        </div>
        <Divider/>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <p>
            {t('auth.notSignedUpCTA')} <Link to="/signupPage">{t('auth.signUp')}</Link>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;