import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {GoogleOAuthProvider} from "@react-oauth/google";

import styles from './style/custom.module.css'

import {OnboardingContextProvider} from "./contexts/OnboardingContext";
import {AuthContextProvider} from "./contexts/AuthContext";

import ProtectedRoute from './components/protectedRoute';
import UnauthenticatedRoute from './components/unauthenticatedRoute';
import RedirectToAppropriatePage from './components/RedirectToAppropriatePage';

import LoginPage from './components/pages/loginPage';
import SignupPage from './components/pages/signupPage';
import ForgotPassword from './components/pages/forgotPassword';
import UserProfile from './components/pages/userProfile';
import WebHook from './components/pages/webhook';
import NotFound404 from "./components/pages/NotFound404";

import NavBar from './components/navbar';

const App = () => {
  return (
    <div className={styles.App}>
      <Router>

        <GoogleOAuthProvider clientId={process.env.REACT_APP_GMAIL_CLIENTID as string}>
          <AuthContextProvider>
            <OnboardingContextProvider>
              <NavBar />
              <Routes>
                <Route path='/webHook' element={<WebHook />} />
                <Route element={<UnauthenticatedRoute />}>
                  <Route path="/signupPage" element={<SignupPage />} />
                  <Route path='/forgotPassword' element={<ForgotPassword />} />
                  <Route path="/login" element={<LoginPage />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                  <Route path="/userProfile" element={<UserProfile />} />
                </Route>
                <Route path="/" element={<RedirectToAppropriatePage />} />
                <Route path="*" element={<NotFound404 />} />
              </Routes>
            </OnboardingContextProvider>
          </AuthContextProvider>
        </GoogleOAuthProvider>
      </Router>
    </div>
  );
}

export default App;