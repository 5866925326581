import React, {useMemo} from 'react';
import styles from "../style/custom.module.css";
import {useTranslation} from "react-i18next";

export const PasswordIssueType = {
    length: 'length',
    specialCharacter: 'specialCharacter',
    number: 'number',
    capitalLetter: 'capitalLetter',
} as const;

export default function useStrongPassword(password: string) {
    return useMemo(() => {
        let issues = [];
        if (password.length <= 7) {
            issues.push(PasswordIssueType.length);
        }
        // Check for special character except /
        if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
            issues.push(PasswordIssueType.specialCharacter);
        }
        // Check for at least one number
        if (!/\d/.test(password)) {
            issues.push(PasswordIssueType.number);
        }
        // Check for at least one capital letter
        if (!/[A-Z]/.test(password)) {
            issues.push(PasswordIssueType.capitalLetter);
        }
        return issues;
    }, [password]);
}

export function PasswordStrengthIndicator({passwordIssues}: Readonly<{passwordIssues: string[]}>) {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const {t} = useTranslation();
    return (
        <section className={styles.passwordStrength} data-strength={4 - (passwordIssues.length)}>
            <span>{t('auth.passwordStrength.title')}</span>
            <ol className={styles.passwordStrengthMeter}>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
            </ol>
            <ol className={styles.passwordStrengthInfoStatus}>
                {Object.entries(PasswordIssueType).map(([key, issue]) => (
                    <li data-complete={!passwordIssues.includes(issue)} key={key}>{t(`auth.passwordStrength.${issue}`)}</li>
                ))}
            </ol>
        </section>
    );
}