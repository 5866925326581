import React, { useState } from 'react';
import { Row, Form, Input, Button, Divider, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {Trans, useTranslation} from "react-i18next";
import { APIPost } from '../../helpers/http.helper';
import styles from '../../style/custom.module.css'
import useStrongPassword, {PasswordStrengthIndicator} from "../../hooks/useStrongPassword";
import OnboardingStepper from "../onboardingStepper";
import i18next from "i18next";

const SignupPage = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [userCreated, setUserCreated] = useState(false);

  const handleOnPasswordChange = (e: any) => {
    setPassword(e.target.value);
  }

  const handleOnConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  }

  const passwordIssues = useStrongPassword(password);

  const onFinish = async (values: any) => {
    if (passwordIssues.length > 0) return;

    const registerResponse = await APIPost('', {
      email: values.email,
      name: values.name,
      password: btoa(values.password),
      language: i18next.resolvedLanguage,
    });

    if (registerResponse.status !== 201) {
      const message = (<Trans
          i18nKey={'auth.errors.registrationFailed'}
          components={{
            // TODO (LOW) - Trans is *really* not liking usage of Link here, for now use a plain anchor at the expense of a page reload.
            // anchorlogin: <Link to="/login">Log In</Link>,
            anchorlogin: <a href="/login">Log In</a>,
            anchorsupport: <a href="https://support.newmail.ai/folders/support" rel="noreferrer" target="_blank">Contact
              Support</a>,
            smaller: <div style={{fontSize: '14px', lineHeight: 1.4, marginTop: '8px'}} />
          }}
          shouldUnescape={true}
        />
      )

      notification.error({
        message,
        // message: <strong>boo</strong>,
        placement: "bottomRight"
      })
      return;
    }
    else {
      notification.success({
        message: t('auth.success.userCreated'),
        placement: "bottomRight"
      });
      setUserCreated(true);

      setTimeout(() => {
        navigate('/login');
      }, 1000);
    }
  };

  return (
    <div className={styles.signupPage}>
      <OnboardingStepper>
        <Form
          name="normal_signup"
          className={styles.signupForm}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div className={styles.signupFormInputs}>
            <div>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: t('auth.errors.invalidEmail'),
                  },
                  {
                    required: true,
                    message: t('auth.errors.requiredEmail'),
                  },
                ]}
              >
                <Input placeholder={t('auth.emailLabel')} value={email} onChange={(e) => setEmail(e.target.value)}/>
              </Form.Item>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('auth.errors.requiredName'),
                  },
                ]}
              >
                <Input placeholder={t('auth.nameLabel')} value={name} onChange={(e) => setName(e.target.value)}/>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{required: true, message: t('auth.errors.requiredPassword')}]}
              >
                <Input.Password placeholder={t('auth.passwordLabel')} value={password} onChange={handleOnPasswordChange}/>
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  {required: true, message: t('auth.errors.requiredConfirmPassword')},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('auth.errors.passwordDoesNotMatch')));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('auth.confirmPasswordLabel')} value={confirmPassword}
                                onChange={handleOnConfirmPasswordChange}/>
              </Form.Item>
            </div>
            <PasswordStrengthIndicator passwordIssues={passwordIssues}/>
          </div>
          <br/>
          {!userCreated &&
            <Form.Item>
              <Button htmlType="submit" className="w-100 btn btn-sm btn-primary">
                {t('auth.signUp')}
              </Button>
            </Form.Item>
          }
          <Divider/>
          <Row>
            <p>{t('auth.alreadyHaveAccountCTA')} <Link to="/login">{t('auth.login')}</Link></p>
          </Row>
        </Form>
      </OnboardingStepper>
    </div>
  );
};

export default SignupPage;
