import * as React from 'react';
import { Button, Form, Input, Popconfirm } from 'antd';
import { APIPut } from '../helpers/http.helper';
import { NumberOutlined } from '@ant-design/icons';
import styles from '../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';
import {useTranslation} from "react-i18next";

interface Category {
    id?: number;
    markForDeletion?: boolean;
    categoryName: string;
    categoryDescription: string;
}

const CustomCategory = ({ setCategories, categories }: {setCategories:  React.Dispatch<React.SetStateAction<Category[]>>, categories: Category[]}) => {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const updateCategories = async (categories: Category[]) => {
        const response = await APIPut('categories', {
            categories: categories.filter(
              (c: any) => c.id || (c.categoryName.trim().length > 0 && c.categoryDescription.trim().length > 0)),
        });

        if (response.status === 200) {
            setCategories(await response.json());
        } else {
            throw new Error(t('profile.preferences.errors.categoriesUpdateFailed'));
        }
    };

    const handleInputChange = (e: any, index: any) => {
        const newCategories = [...categories];
        if (e.target.name === 'categoryName') {
            newCategories[index].categoryName = e.target.value;
        } else if (e.target.name === 'categoryDescription') {
            newCategories[index].categoryDescription = e.target.value;
        }

        setCategories(newCategories);
    };




    const handleAddRow = () => {
        setCategories([
            ...categories,
            {
                categoryName: '',
                categoryDescription: '',
            },

        ]);
    };

    const handleRemove = async (_: any, category: Category) => {
        let newCategories = [...categories];
        if (category.id) {
            newCategories = newCategories.map(entry => {
                return entry.id !== category.id ? entry : {...entry, markForDeletion: true};
            });

            setCategories(newCategories);
            await updateCategories(newCategories);
        } else {
            setCategories(newCategories.filter(entry => entry !== category));
        }
    };

    return (
        <div className={styles.userProfilePreferencesCategories}>
            {categories.map((category, index,) => (
                <div key={category.id ? `cat_${category.id}` : `index_${index}`}>
                    <Form className={styles.userProfilePreferencesCategories__form} component="div" form={form}>
                        <Input value={category.id} type="hidden" name="id" />
                        <label>{t('profile.preferences.categoryNameLabel')}</label>

                        <Input maxLength={15} value={category.categoryName}
                            required type="text" name="categoryName" placeholder={t('profile.preferences.categoryNamePlaceholder')}
                            prefix={<NumberOutlined className="site-form-item-icon" />}
                            autoFocus={category.categoryName === ''}
                            onChange={(e) => handleInputChange(e, index)} />

                        <label>{t('profile.preferences.categoryDescriptionLabel')}</label>

                        <Input.TextArea
                          autoSize={{ minRows: 1, maxRows: 6 }}
                            maxLength={500}
                            value={category.categoryDescription}
                            required
                            name="categoryDescription"
                            placeholder={t('profile.preferences.categoryDescriptionPlaceholder')}
                            onChange={(e) => handleInputChange(e, index)}
                        />

                        <Popconfirm title={t('profile.preferences.confirmDeleteCategory')} cancelText={t('common.cancel')} okText={t('common.confirm')} okType="danger" onConfirm={(e) => handleRemove(e, category)}>
                            <Button className={["btn btn-danger btn-sm"].join(' ')}>{t('common.remove')}</Button>
                        </Popconfirm>
                    </Form>
                </div>
            ))}
            <Form.Item>
                <Button className={["btn btn-primary btn-sm"].join(' ')} onClick={handleAddRow}>{t('profile.preferences.addCategoryButton')}</Button>
            </Form.Item>

        </div>
    );

}
export default CustomCategory;
