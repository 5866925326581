import React from "react";
import { Button, Row, Col } from "antd";
import styles from "../style/custom.module.css";
import "bootstrap/dist/css/bootstrap.css";
import { Helmet } from 'react-helmet';
import {useTranslation} from "react-i18next";
import {useOnboardingContext} from "../contexts/OnboardingContext";

const Membership = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const {t} = useTranslation();
  const {subscription} = useOnboardingContext();

  const formatDate = (date: any) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const isTrialPlanActive = Boolean(subscription && subscription.type.toLowerCase() === 'trial');
  const isPaidPlan = !isTrialPlanActive && subscription;
  const isPaidPlanActive = Boolean(isPaidPlan && subscription && subscription.expiresIn > 0);

  // @ts-ignore
  const planName: string = t(`profile.membership.plans.${subscription?.description || 'Free Demo'}`)

  return (
    <>
      <Helmet>
        {!isPaidPlanActive && (
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
        )}
      </Helmet>

      <div className="card">
        <div className={[styles.CardTitle, "card-header"].join(" ")}>
          {t('profile.membership.title')}
        </div>

        <div className="card-body">
          <Col style={{ display: isPaidPlanActive ? "none" : "block" }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                {/*@ts-ignore*/}
                <stripe-pricing-table pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE}
                  publishable-key={process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY}>
                  {/*@ts-ignore*/}
                </stripe-pricing-table>
              </Col>
            </Row>
          </Col>


          {isPaidPlan && (
            <Col>
              <Row gutter={[16, 16]}>

                <Col span={8}>
                <span className={[styles.lables, "form-label"].join(" ")}>
                  {t('profile.membership.currentPlanLabel')}:
                  <span style={{ fontWeight: isPaidPlanActive ? "bold" : "" }}>
                    {planName}
                  </span>
                </span>
                </Col>
                <Col span={5}>
                <span className={[styles.lables, "form-label"].join(" ")}>
                  {t('profile.membership.currentPlanStatusLabel')}:{` `}
                  <span
                    style={{
                      color: isPaidPlanActive ? "green" : "red",
                      fontWeight: isPaidPlanActive ? "bold" : "",
                    }}
                  >
                    {isPaidPlanActive ? t('common.active') : t('common.inactive')}
                  </span>
                </span>
                </Col>
                <Col span={9}>
                <span className={[styles.lables, "form-label"].join(" ")}>
                  {t('profile.membership.nextRenewalDateLabel')}:{" "}
                  <span
                    style={{
                      color:
                        subscription && subscription?.expiresIn < 432000 // 5 days in seconds
                          ? "red"
                          : subscription && subscription?.expiresIn < 864000 // 10 days in seconds
                            ? "blue"
                            : "green",
                      fontWeight: isPaidPlanActive ? "bold" : "",
                    }}
                  >
                    {" "}
                    {formatDate(Date.now() + (subscription?.expiresIn * 1000))}
                    {" "}
                  </span>{" "}
                </span>
                </Col>
              </Row>
              <br />
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  {isPaidPlanActive && (
                    <a href="https://billing.stripe.com/p/login/9AQ9DM9Cp9PMg9y7ss" target="_blank" rel="noreferrer">
                      <Button
                        className={[
                          styles.buttonText,
                          "btn btn-success btn-sm",
                        ].join(" ")}
                      >
                        {t('profile.membership.manageMembershipButton')}
                      </Button></a>
                  )}
                </Col>
              </Row>
            </Col>
          )}
        </div>
      </div>
    </>
  );
};

export default Membership;
