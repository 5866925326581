import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import styles from '../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';
import { useAuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./languageSelect";

const NavBar = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const { t } = useTranslation();
  const { loggedIn, logout} = useAuthContext();

  return (
    <div className={styles.navbar}>
      <img className={styles.logo} src="../logo.png" alt={t('navBar.logoAlt')} />
      <Link to="/" style={{textDecoration: 'none'}}><h2
          className={styles.newMailHome}> {t('navBar.logoText')}</h2></Link>
      <div className={styles.navbarLinks}>
        <a href={t('navBar.helpCenterLink')} target="_blank" rel="noreferrer">{t('navBar.helpCenterText')}</a>
        <LanguageSelect />
        { loggedIn && <Button className={[styles.buttonText, "btn btn-primary btn-sm"].join(' ')} onClick={logout}>{t('auth.logout')}</Button>}
      </div>
    </div>
  );
};

export default NavBar;
